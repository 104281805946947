import React, { useState, Dispatch, SetStateAction } from "react"

interface StateContext {
  theme: string
  setTheme: Dispatch<SetStateAction<string>>
}

const defaultState = {
  theme: "start",
  setTheme: () => {},
}

export const GlobalStateContext = React.createContext<StateContext | null>(
  defaultState
)

export default ({ children }) => {
  const [theme, setTheme] = useState("start")

  const store = {
    theme,
    setTheme,
  }

  return (
    <GlobalStateContext.Provider value={store}>
      {children}
    </GlobalStateContext.Provider>
  )
}
